const  router = [
	{
		path: '/indexAdmin',
		component: () => import('../../../pages/platform/dashboard/Index.vue'),
		meta: {title: '控制台'},
		name: 'homeIndex'
	}
];

export default router
