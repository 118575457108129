const  router = [
	{
		path: "/partner/layout",
		component: () => import('../../../pages/partner/Layout.vue'),
		meta: {title: '合伙人'},
		name: 'partner_layout',
		children:[
			{
				path: "/partner",
				component: () => import('../../../pages/partner/Setting.vue'),
				meta: {title: '合伙人设置'},
				name: 'partner_shezhi'
			},
			{
				path: "/partner/shareholder",
				component: () => import('../../../pages/partner/Shareholder.vue'),
				meta: {title: '股东合伙人'},
				name: 'partner_shareholder'
			},
			{
				path: "/partner/shareholder/detail",
				component: () => import('../../../pages/partner/ShareholderDetail.vue'),
				meta: {title: '股东合伙人详情'},
				name: 'partner_shareholder_detail'
			},
			{
				path: "/partner/custom",
				component: () => import('../../../pages/partner/Custom.vue'),
				meta: {title: '自定义合伙人'},
				name: 'partner_custom'
			},
			{
				path: "/partner/custom/detail",
				component: () => import('../../../pages/partner/CustomDetail.vue'),
				meta: {title: '自定义合伙人详情'},
				name: 'partner_custom_detail'
			},
			{
				path: "/partner/employeePartner",
				component: () => import('../../../pages/partner/EmployeePartner.vue'),
				meta: {title: '员工合伙人'},
				name: 'partner_employeePartner'
			},
			{
				path: "/partner/employeePartner/detail",
				component: () => import('../../../pages/partner/EmployeePartnerDetail.vue'),
				meta: {title: '员工合伙人详情'},
				name: 'partner_employeePartner_detail'
			},
			{
				path: "/partner/memberPartner",
				component: () => import('../../../pages/partner/MemberPartner.vue'),
				meta: {title: '会员合伙人'},
				name: 'partner_huiyuanPartner'
			},
			{
				path: "/partner/memberPartner/detail",
				component: () => import('../../../pages/partner/MemberPartnerDetail.vue'),
				meta: {title: '会员合伙人详情'},
				name: 'partner_huiyuanPartner_detail'
			},
			{
				path: "/partner/withdraw",
				component: () => import('../../../pages/partner/Withdraw.vue'),
				meta: {title: '提现记录'},
				name: 'partner_withdraw'
			},
			{
				path: "/partner/poster",
				component: () => import('../../../pages/partner/Poster.vue'),
				meta: {title: '海报库'},
				name: 'partner_poster'
			},
			{
				path: "/partner/poster/edit",
				component: () => import('../../../pages/partner/EditPoster.vue'),
				meta: {title: '编辑海报库'},
				name: 'partner_poster_edit'
			},
		],
	}
];

export default router