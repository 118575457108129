const  router = [
	{
		path: "/appointment/layout",
		component: () => import('../../../pages/appointment/Layout.vue'),
		meta: {title: '预约排班'},
		name: 'appointment_layout',
		children:[
			{
				path: "/appointment",
				component: () => import('../../../pages/appointment/Serviceman.vue'),
				meta: {title: '服务人员'},
				name: 'appointment_index'
			},
			{
				path: "/appointment/servicemanDetail",
				component: () => import('../../../pages/appointment/ServicemanDetail.vue'),
				meta: {title: '服务人员详情'},
				name: 'appointment_index_detail'
			},
			{
				path: "/appointment/applyService",
				component: () => import('../../../pages/appointment/ApplyService.vue'),
				meta: {title: '服务人员申请'},
				name: 'appointment_service_apply'
			},
			{
				path: "/appointment/order",
				component: () => import('../../../pages/appointment/Order.vue'),
				meta: {title: '预约工单'},
				name: 'appointment_order'
			},
			{
				path: "/appointment/orderDetail",
				component: () => import('../../../pages/appointment/OrderDetail.vue'),
				meta: {title: '预约工单详情'},
				name: 'appointment_order_detail'
			},
			{
				path: "/appointment/kanban",
				component: () => import('../../../pages/appointment/Kanban.vue'),
				meta: {title: '工单预约看板'},
				name: 'appointment_kanban'
			},
			{
				path: "/appointment/shezhi",
				component: () => import('../../../pages/appointment/Setting.vue'),
				meta: {title: '工单设置'},
				name: 'appointment_shezhi'
			},
			{
				path: "/appointment/performanceType",
				component: () => import('../../../pages/appointment/PerformanceType.vue'),
				meta: {title: '业绩类型'},
				name: 'appointment_performance_type'
			},
			{
				path: "/appointment/performanceApproval",
				component: () => import('../../../pages/appointment/PerformanceApproval.vue'),
				meta: {title: '业绩核准'},
				name: 'appointment_performance_approval'
			},
			{
				path: "/appointment/employeePerformance",
				component: () => import('../../../pages/appointment/EmployeePerformance.vue'),
				meta: {title: '员工业绩'},
				name: 'appointment_employee_performance'
			},
			{
				path: "/appointment/supp",
				component: () => import('../../../pages/appointment/supp.vue'),
				meta: {title: '补单审核'},
				name: 'appointment_supp'
			},
		],
	}
];

export default router