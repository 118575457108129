const  router = [
	{
		path: "/setting/layout",
		component: () => import('../../../pages/setting/Layout.vue'),
		meta: {title: '设置'},
		name: 'setting_layout',
		children:[
			{
				path: "/setting",
				component: () => import('../../../pages/setting/Index.vue'),
				meta: {title: '站点资料'},
				name: 'setting_index',
			},
			{
				path: "/account",
				component: () => import('../../../pages/setting/Account.vue'),
				meta: {title: '账号管理'},
				name: 'setting_account',
			},
			{
				path: "/setting/group",
				component: () => import('../../../pages/setting/Group.vue'),
				meta: {title: '权限组'},
				name: 'setting_group',
			},
			{
				path: "/setting/sms",
				component: () => import('../../../pages/setting/Sms.vue'),
				meta: {title: '短信设置'},
				name: 'setting_sms',
			},
			{
				path: "/setting/integral",
				component: () => import('../../../pages/setting/Integral.vue'),
				meta: {title: '积分设置'},
				name: 'setting_integral',
			},
			{
				path: "/setting/balance",
				component: () => import('../../../pages/setting/Balance.vue'),
				meta: {title: '余额设置'},
				name: 'setting_balance',
			},
			{
				path: "/setting/buy",
				component: () => import('../../../pages/setting/Buy.vue'),
				meta: {title: '买单设置'},
				name: 'setting_buy',
			},
			{
				path: "/setting/printer",
				component: () => import('../../../pages/setting/Printer.vue'),
				meta: {title: '打印机设置'},
				name: 'setting_printer',
			},

		],
	}
];

export default router
