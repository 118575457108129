const  router = [
	{
		path: "/appointmentAdmin/layout",
		component: () => import('../../../pages/platform/appointmentAdmin/Layout.vue'),
		meta: {title: '预约排班'},
		name: 'appointmentAdmin_layout',
		children:[
			// {
			// 	path: "/appointmentAdmin",
			// 	component: () => import('../../../pages/platform/appointmentAdmin/Serviceman.vue'),
			// 	meta: {title: '服务人员'},
			// 	name: 'appointmentAdmin_index'
			// },
			// {
			// 	path: "/appointmentAdmin/servicemanDetail",
			// 	component: () => import('../../../pages/platform/appointmentAdmin/ServicemanDetail.vue'),
			// 	meta: {title: '服务人员详情'},
			// 	name: 'appointmentAdmin_index_detail'
			// },
			// {
			// 	path: "/appointmentAdmin/applyService",
			// 	component: () => import('../../../pages/platform/appointmentAdmin/ApplyService.vue'),
			// 	meta: {title: '服务人员申请'},
			// 	name: 'appointmentAdmin_service_apply'
			// },
			{
				path: "/appointmentAdmin",
				component: () => import('../../../pages/platform/appointmentAdmin/Order.vue'),
				meta: {title: '预约工单'},
				name: 'appointmentAdmin_order'
			},
			{
				path: "/appointmentAdmin/orderDetail",
				component: () => import('../../../pages/platform/appointmentAdmin/OrderDetail.vue'),
				meta: {title: '工单订单详情'},
				name: 'appointmentAdmin_order_detail'
			},
			{
				path: "/appointmentAdmin/kanban",
				component: () => import('../../../pages/platform/appointmentAdmin/Kanban.vue'),
				meta: {title: '工单看板'},
				name: 'appointmentAdmin_kanban'
			},
			{
				path: "/appointmentAdmin/shezhi",
				component: () => import('../../../pages/platform/appointmentAdmin/Setting.vue'),
				meta: {title: '工单设置'},
				name: 'appointmentAdmin_shezhi'
			},
			{
				path: "/appointment/performanceType",
				component: () => import('../../../pages/platform/appointmentAdmin/PerformanceType.vue'),
				meta: {title: '业绩类型'},
				name: 'appointmentAdmin_performance_type'
			},
			{
				path: "/appointmentAdmin/performanceApproval",
				component: () => import('../../../pages/platform/appointmentAdmin/PerformanceApproval.vue'),
				meta: {title: '业绩核准'},
				name: 'appointmentAdmin_performance_approval'
			},
			{
				path: "/appointmentAdmin/employeePerformance",
				component: () => import('../../../pages/platform/appointmentAdmin/EmployeePerformance.vue'),
				meta: {title: '员工业绩'},
				name: 'appointmentAdmin_employee_performance'
			},
		],
	}
];

export default router
