const  router = [
	{
		path: "/siteAdmin/layout",
		component: () => import('@/pages/platform/site/Layout.vue'),
		meta: {title: '站点'},
		name: 'siteAdmin_layout',
		children:[
			{
				path: "/siteAdmin",
				component: () => import('@/pages/platform/site/EditClass.vue'),
				meta: {title: '站点分类'},
				name: 'siteAdmin_index'
			},
			{
				path: "/siteAdmin/manage",
				component: () => import('@/pages/platform/site/Index.vue'),
				meta: {title: '站点管理'},
				name: 'siteAdmin_manage'
			},
			{
				path: "/siteAdmin/edit",
				component: () => import('@/pages/platform/site/EditSite.vue'),
				meta: {title: '编辑站点'},
				name: 'siteAdmin_manage_edit'
			},
			{
				path: "/siteAdmin/master",
				component: () => import('@/pages/platform/site/Master.vue'),
				meta: {title: '站点列表'},
				name: 'siteAdmin_master'
			},
			{
				path: "/siteAdmin/user",
				component: () => import('@/pages/platform/site/User.vue'),
				meta: {title: '站点列表'},
				name: 'siteAdmin_user'
			},
		],
	}
];

export default router
