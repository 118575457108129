const  router = [
	{
		path: "/memberAdmin/layout",
		component: () => import('@/pages/platform/member/Layout.vue'),
		meta: {title: '会员管理'},
		name: 'memberAdmin_layout',
		children:[
			{
				path: "/memberAdmin",
				component: () => import('@/pages/platform/member/Index.vue'),
				meta: {title: '会员列表'},
				name: 'memberAdmin_index',
			},
			{
				path: "/memberAdmin/rank",
				component: () => import('@/pages/platform/member/Rank.vue'),
				meta: {title: '会员等级'},
				name: 'memberAdmin_rank',
			},
			{
				path: "/memberAdmin/elder",
				component: () => import('@/pages/platform/member/Elder.vue'),
				meta: {title: '长者管理'},
				name: 'memberAdmin_elder',
			},
			{
				path: "/memberAdmin/editelder",
				component: () => import('@/pages/platform/member/editElder.vue'),
				meta: {title: '编辑长者'},
				name: 'memberAdmin_editelder'
			},
			{
				path: "/memberAdmin/integral",
				component: () => import('@/pages/platform/member/Integral.vue'),
				meta: {title: '会员积分'},
				name: 'memberAdmin_integral',
			},
			{
				path: "/memberAdmin/detail",
				component: () => import('@/pages/platform/member/Detail.vue'),
				meta: {title: '会员详情'},
				name: 'memberAdmin_index_detail',
			},
            {
                path: "/memberAdmin/integralseting",
                component: () => import('@/pages/setting/Integral.vue'),
                meta: {title: '积分设置'},
                name: 'memberAdmin_integrseting',
            },
		]
	}
];

export default router