const  router = [
	{
		path: "/settingAdmin/layout",
		component: () => import('../../../pages/platform/setting/Layout.vue'),
		meta: {title: '设置'},
		name: 'settingAdmin_layout',
		children:[
			{
				path: "/settingAdmin",
				component: () => import('@/pages/platform/setting/Index.vue'),
				meta: {title: '店铺资料'},
				name: 'settingAdmin_index',
			},
			{
				path: "/settingAdmin/account",
				component: () => import('@/pages/platform/setting/Account.vue'),
				meta: {title: '账号管理'},
				name: 'settingAdmin_account',
			},
			{
				path: "/settingAdmin/auth",
				component: () => import('@/pages/platform/setting/Auth.vue'),
				meta: {title: '权限管理'},
				name: 'settingAdmin_auth',
			},
			{
				path: "/settingAdmin/group",
				component: () => import('@/pages/platform/setting/Group.vue'),
				meta: {title: '权限组'},
				name: 'settingAdmin_group',
			},
            {
                path: "/settingAdmin/adv",
                component: () => import('@/pages/adv/Index.vue'),
                meta: {title: '首页换灯片'},
                name: 'settingAdmin_adv',
            },
			{
				path: "/settingAdmin/access",
				component: () => import('../../../pages/platform/setting/Access.vue'),
				meta: {title: '随访频率'},
				name: 'settingAamin_access',
			},
			{
				path: "/settingAdmin/region",
				component: () => import('../../../pages/platform/setting/Region.vue'),
				meta: {title: '地址库'},
				name: 'settingAdmin_region',
			},
            {
                path: "/settingAdmin/payment",
                component: () => import('../../../pages/platform/setting/Payment.vue'),
                meta: {title: '支付配置'},
                name: 'settingAdmin_payment',
            },
			{
				path: "/settingAdmin/notice",
				component: () => import('../../../pages/platform/setting/Notice.vue'),
				meta: {title: '支付配置'},
				name: 'settingAdmin_notice',
			},
            {
                path: "/settingAdmin/drug",
                component: () => import('../../../pages/platform/setting/DrugLib.vue'),
                meta: {title: '药品信息库管理'},
                name: 'settingAdmin_drug',
            },
			// {
			// 	path: "/settingAdmin/integral",
			// 	component: () => import('../../../pages/platform/setting/Integral.vue'),
			// 	meta: {title: '积分设置'},
			// 	name: 'settingAdmin_integral',
			// },
			// {
			// 	path: "/settingAdmin/balance",
			// 	component: () => import('../../../pages/platform/setting/Balance.vue'),
			// 	meta: {title: '余额设置'},
			// 	name: 'settingAdmin_balance',
			// },
			// {
			// 	path: "/settingAdmin/buy",
			// 	component: () => import('../../../pages/platform/setting/Buy.vue'),
			// 	meta: {title: '买单设置'},
			// 	name: 'settingAdmin_buy',
			// },
			// {
			// 	path: "/settingAdmin/printer",
			// 	component: () => import('../../../pages/platform/setting/Printer.vue'),
			// 	meta: {title: '打印机设置'},
			// 	name: 'settingAdmin_printer',
			// },
		],
	}
];

export default router
