const  router = [
	{
		path: "/activity/layout",
		component: () => import('../../../pages/activity/Layout.vue'),
		meta: {title: '活动管理'},
		name: 'activity_layout',
		children:[
			{
				path: "/activity",
				component: () => import('../../../pages/activity/Index.vue'),
				meta: {title: '活动列表'},
				name: 'activity'
			},
			{
				path: "/activity/editActivity",
				component: () => import('../../../pages/activity/From.vue'),
				meta: {title: '编辑活动'},
				name: 'activity_edit'
			},
			{
				path: "/activity/fileActivity",
				component: () => import('../../../pages/activity/files.vue'),
				meta: {title: '活动附件'},
				name: 'activity_files'
			},
			{
				path: "/activity/joinActivity",
				component: () => import('../../../pages/activity/join.vue'),
				meta: {title: '参与管理'},
				name: 'activity_join'
			},
		],
	}
];

export default router