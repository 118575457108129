const  router = [
	{
		path: "/project/layout",
		component: () => import('../../../pages/project/Layout.vue'),
		meta: {title: '项目'},
		name: 'project_layout',
		children:[
			
			{
				path: "/project",
				component: () => import('../../../pages/project/Index.vue'),
				meta: {title: '服务分类'},
				name: 'project_index'
			},
			{
                path: "/project/manage",
                component: () => import('../../../pages/project/project.vue'),
                meta: {title: '项目管理'},
                name: 'project_manage'
            },
            {　
                path: "/project/platform",
                component: () => import('../../../pages/project/PlatformProject.vue'),
                meta: {title: '服务项目库'},
                name: 'project_platform'
            },
			{
				path: "/project/edit",
				component: () => import('../../../pages/project/EditProject.vue'),
				meta: {title: '编辑项目'},
				name: 'project_manage_edit'
			},
			{
				path: "/project/oncecard",
				component: () => import('../../../pages/project/Oncecard.vue'),
				meta: {title: '次卡管理'},
				name: 'project_oncecard'
			},
			{
				path: "/project/oncecard/edit",
				component: () => import('../../../pages/project/EditOncecard.vue'),
				meta: {title: '编辑次卡'},
				name: 'project_oncecard_edit'
			},
			{
				path: "/project/orderOncecard",
				component: () => import('../../../pages/project/OrderOncecard.vue'),
				meta: {title: '次卡订单'},
				name: 'project_orderOncecard'
			},
            {
                path: "/project/customerToOrder",
                component: () => import('../../../pages/project/customerToOrder.vue'),
                meta: {title: '次卡代客下单'},
                name: 'project_customerToOrder'
            },
            {
                path: "/project/orderOncecardLog",
                component: () => import('../../../pages/project/OnceCardOrderLog.vue'),
                meta: {title: '核销记录'},
                name: 'project_orderOncecardLog'
            },

			{
				path: "/project/orderOncecardDetail",
				component: () => import('../../../pages/project/OrderOncecardDetail.vue'),
				meta: {title: '次卡订单详情'},
				name: 'project_orderOncecard_detail'
			},
            {
                path: "/project/projectOrder",
                component: () => import('../../../pages/project/ProjectOrderList.vue'),
                meta: {title: '项目订单管理'},
                name: 'project_order_list'
            },
			{
				path: "/project/projectOrderDetail",
				component: () => import('../../../pages/project/ProjectOrderDetail.vue'),
				meta: {title: '项目订单管理'},
				name: 'project_order_detail'
			},
            {
                path: "/project/projectAfter",
                component: () => import('../../../pages/project/ProjectAfterList.vue'),
                meta: {title: '订单售后管理'},
                name: 'project_after_list'
            },
            {
                path: "/project/projectAfterDetail",
                component: () => import('../../../pages/project/ProjectAfterDetail.vue'),
                meta: {title: '订单售后详情'},
                name: 'project_after_detail'
            }
		],
	}
];

export default router