const  router = [
	{
		path: "/coupon/layout",
		component: () => import('../../../pages/coupon/Layout.vue'),
		meta: {title: '优惠卷'},
		name: 'coupon_layout',
		children:[
			{
				path: "/coupon",
				component: () => import('../../../pages/coupon/Index.vue'),
				meta: {title: '上架中'},
				name: 'coupon_online'
			},
			{
				path: "/coupon/offline",
				component: () => import('../../../pages/coupon/Offline.vue'),
				meta: {title: '下架过期'},
				name: 'coupon_offline'
			},
			{
				path: "/coupon/order",
				component: () => import('../../../pages/coupon/Order.vue'),
				meta: {title: '领取记录'},
				name: 'coupon_order'
			},
			{
				path: "/coupon/logs",
				component: () => import('../../../pages/coupon/Logs.vue'),
				meta: {title: '核销记录'},
				name: 'coupon_logs'
			}
		],
	}
];

export default router