const  router = [
	{
		path: "/member/layout",
		component: () => import('../../../pages/member/Layout.vue'),
		meta: {title: '用户管理'},
		name: 'member_layout',
		children:[
			{
				path: "/member/elder",
				component: () => import('../../../pages/member/Elder.vue'),
				meta: {title: '长者管理'},
				name: 'member_elder',
			},
			{
				path: "/member/editelder",
				component: () => import('../../../pages/member/editElder.vue'),
				meta: {title: '编辑长者'},
				name: 'member_elder_edit'
			},
			{
				path: "/member/elderDetail",
				component: () => import('../../../pages/member/elderDetail.vue'),
				meta: {title: '长者详情'},
				name: 'member_elder_detail'
			},
			{
				path: "/member/access",
				component: () => import('../../../pages/member/access.vue'),
				meta: {title: '随访记录'},
				name: 'member_access',
			}
			// {
			// 	path: "/member",
			// 	component: () => import('../../../pages/member/Index.vue'),
			// 	meta: {title: '会员管理'},
			// 	name: 'member_index',
			// },
			// {
			// 	path: "/member/rank",
			// 	component: () => import('../../../pages/member/Rank.vue'),
			// 	meta: {title: '会员等级'},
			// 	name: 'member_rank',
			// },
			// {
			// 	path: "/member/integral",
			// 	component: () => import('../../../pages/member/Integral.vue'),
			// 	meta: {title: '会员积分'},
			// 	name: 'member_integral',
			// },
			// {
			// 	path: "/member/money",
			// 	component: () => import('../../../pages/member/Money.vue'),
			// 	meta: {title: '会员余额'},
			// 	name: 'member_money',
			// },
			// {
			// 	path: "/member/detail",
			// 	component: () => import('../../../pages/member/Detail.vue'),
			// 	meta: {title: '会员详情'},
			// 	name: 'member_index_detail',
			// },
			// {
			// 	path: "/member/supplement",
			// 	component: () => import('../../../pages/member/Supplement.vue'),
			// 	meta: {title: '线下补单'},
			// 	name: 'member_supplement',
			// },
			// {
			// 	path: "/member/buy",
			// 	component: () => import('../../../pages/member/Buy.vue'),
			// 	meta: {title: '买单记录'},
			// 	name: 'member_buy',
			// },
		]
	}
];

export default router