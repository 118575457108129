import Vue from 'vue'
import VueRouter from 'vue-router'
import axios from "axios";
Vue.use(VueRouter);

const routerList = [
	{
	    path: '/404',
	    component: () => import( '../../pages/404.vue'),
	    name:'nodata404',
	    meta: {title: '抱歉您找的页面失踪了'},
	},
	{
	    path: '/auth',
	    component: () => import( '../../pages/Auth.vue'),
	    name:'auth',
	    meta: {title: '登录校验中'},
	},
	{
		path: '/login',
		component: () => import( '../../pages/Login.vue'),
		name:'login',
		meta: {title: '登录页面'},
	},

];

const storeRouter = {
	    path: "/layout",
	    component: () => import('../../pages/Layout.vue'),
	    meta: {title: '总后台'},
	    name: 'layout',
		children:[
			{
			    path: '/refresh',
			    component: () => import( '../../pages/Refresh.vue'),
			    name:'refresh',
			    meta: {title: '刷新页面'},
			}
		]
}
const files = require.context('./routers', false, /\.js$/)


files.keys().forEach(key => {
	let menuLocal = files(key).default;
	for(var  a in menuLocal){
		storeRouter.children.push(menuLocal[a]);
	}
})

const adminFiles = require.context('./admin', false, /\.js$/)
adminFiles.keys().forEach(key => {
	let menuLocal = adminFiles(key).default;

	for(var  a in menuLocal){
		storeRouter.children.push(menuLocal[a]);
	}
})
routerList.push(storeRouter);


const routerObj = new VueRouter({
    routes:routerList
});


routerObj.beforeEach((to, from, next) => {
     if(to.name == null){
        next({
            path:'/login'
        });
     }else{
       document.title =  to.meta.title + ' - 管理后台'
       next();
     }
})

export default routerObj;
