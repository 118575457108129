const  router = [
    {
        path: "/article/layout",
        component: () => import('@/pages/platform/article/Layout.vue'),
        meta: {title: '文章'},
        name: 'article_layout',
        children:[
            {
                path: "/article",
                component: () => import('@/pages/platform/article/Index.vue'),
                meta: {title: '文章列表'},
                name: 'article_index'
            },
            {
                path: "/article/editArticle",
                component: () => import('@/pages/platform/article/EditArticle.vue'),
                meta: {title: '编辑文章'},
                name: 'article_editArticle'
            },
            {
                path: "/article/category",
                component: () => import('@/pages/platform/article/Category.vue'),
                meta: {title: '文章分类'},
                name: 'article_category'
            },

        ],
    }
];

export default router
