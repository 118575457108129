const  router = [
	{
		path: "/miniapp/layout",
		component: () => import('../../../pages/miniapp/Layout.vue'),
		meta: {title: '优惠卷'},
		name: 'miniapp_layout',
		children:[
			{
				path: "/miniapp",
				component: () => import('../../../pages/miniapp/Index.vue'),
				meta: {title: '小程序'},
				name: 'miniapp_mine'
			},
			{
				path: "/miniapp/callback",
				component: () => import('../../../pages/miniapp/Callback.vue'),
				meta: {title: '小程序授权回调'},
				name: 'miniapp_mine_callback'
			},
			{
				path: "/miniapp/template",
				component: () => import('../../../pages/miniapp/Template.vue'),
				meta: {title: '主题设置'},
				name: 'miniapp_template'
			},
			{
				path: "/miniapp/paymanage",
				component: () => import('../../../pages/miniapp/Paymanage.vue'),
				meta: {title: '支付管理'},
				name: 'miniapp_paymanage'
			},
			{
				path: "/miniapp/recommend",
				component: () => import('../../../pages/miniapp/Recommend.vue'),
				meta: {title: '推荐小程序'},
				name: 'miniapp_recommend'
			},
		],
	}
];

export default router