const  router = [
	{
		path: "/form",
		component: () => import('../../../pages/form/Index.vue'),
		meta: {title: '万能表单'},
		name: 'form_index',
	},
	{
		path: "/form/edit",
		component: () => import('../../../pages/form/Edit.vue'),
		meta: {title: '编辑万能表单'},
		name: 'form_edit',
	},
	{
		path: "/form/answer",
		component: () => import('../../../pages/form/Answer.vue'),
		meta: {title: '参与列表'},
		name: 'form_answer',
	}
];

export default router