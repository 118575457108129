const  router = [
	{
		path: "/look",
		component: () => import('../../../pages/look/Index.vue'),
		meta: {title: '相册'},
		name: 'look_index',
	},
	{
		path: "/look/edit",
		component: () => import('../../../pages/look/Edit.vue'),
		meta: {title: '编辑相册'},
		name: 'look_index_edit',
	}
];

export default router