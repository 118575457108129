const  router = [
	{
		path: "/projectAdmin/layout",
		component: () => import('@/pages/platform/project/Layout.vue'),
		meta: {title: '项目'},
		name: 'projectAdmin_layout',
		children:[

			{
				path: "/projectAdmin",
				component: () => import('@/pages/platform/project/Index.vue'),
				meta: {title: '服务分类'},
				name: 'projectAdmin_index'
			},
			{
				path: "/projectAdmin/manage",
				component: () => import('@/pages/platform/project/project.vue'),
				meta: {title: '项目管理'},
				name: 'projectAdmin_manage'
			},
			{
				path: "/projectAdmin/edit",
				component: () => import('@/pages/platform/project/EditProject.vue'),
				meta: {title: '编辑项目'},
				name: 'projectAdmin_manage_edit'
			},
			{
				path: "/activeAdmin/cates",
				component: () => import('@/pages/platform/active/Cates.vue'),
				meta: {title: '活动分类'},
				name: 'activeAdmin_cates'
			},
			{
				path: "/activeAdmin",
				component: () => import('@/pages/platform/active/Index.vue'),
				meta: {title: '活动管理'},
				name: 'activeAdmin_index'
			},
			{
				path: "/activeAdmin/editActivity",
				component: () => import('@/pages/platform/active/From.vue'),
				meta: {title: '编辑活动'},
				name: 'activeAdmin_edit'
			},


			// {
			// 	path: "/projectAdmin/oncecard",
			// 	component: () => import('../../../pages/platform/project/Oncecard.vue'),
			// 	meta: {title: '次卡管理'},
			// 	name: 'projectAdmin_oncecard'
			// },
			// {
			// 	path: "/projectAdmin/oncecard/edit",
			// 	component: () => import('../../../pages/platform/project/EditOncecard.vue'),
			// 	meta: {title: '编辑次卡'},
			// 	name: 'projectAdmin_oncecard_edit'
			// },
			// {
			// 	path: "/projectAdmin/orderOncecard",
			// 	component: () => import('../../../pages/platform/project/OrderOncecard.vue'),
			// 	meta: {title: '次卡订单'},
			// 	name: 'projectAdmin_orderOncecard'
			// },
			// {
			// 	path: "/projectAdmin/orderOncecardDetail",
			// 	component: () => import('../../../pages/platform/project/OrderOncecardDetail.vue'),
			// 	meta: {title: '次卡订单详情'},
			// 	name: 'projectAdmin_orderOncecard_detail'
			// },
		],
	}
];

export default router
